import Helper from "@/common/helper.js"
import { CartPaymentTypes } from "../../enums";

export default class ReportingIncomeLogModel {
    
    timestamp: string| undefined;
    saleType: CartPaymentTypes | undefined;
    location: string| undefined;
    item: string| undefined;
    user: string| undefined;
    cash: number| undefined;    
    creditCard: number| undefined;    
    serviceFeeCreditCardRate: number| undefined;    
    serviceFeeCreditCardAmount: number| undefined;    
    orderId: string| undefined;
    external: boolean| undefined;

    get timestampDisplay(): string {
        let local = Helper.convertUTCDateToLocalDate(new Date(this.timestamp!))
        return local.toDateString().substring(4) + ", " + local.toLocaleTimeString()
    }

    static from(json: string): ReportingIncomeLogModel {
        let model = Object.assign(new ReportingIncomeLogModel(), json);
                
        return model;
    }

  }

  
  